@import "./variables";
@import "./mixins";

.accessories{
    background-color:$color-main;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0 1rem;
    position: relative;
   
    &-body{
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;

    }
    &-image{
        width: 60%;
        max-height: 80%;
        @include respond(tablet){
            width :65%;
        }

    }
    &-header{
        display: flex;
        justify-content: flex-end;
        margin-right: 3rem;
    }
}

.cta-accessories{
    position: absolute;
    width: 17rem;
    height: 15rem;
    top : 35%;
    transform: translateY(-35%);
    left : .8rem;

    @include respond(tablet){
        width: 90%;
        left: initial;
        top : 31%;
        transform: translateY(-30%);
        
    }
}

.link-disabled {
    pointer-events: none;
    position: relative;
    cursor: default;
}
.link-construction{
    width: 10rem;;
    position: absolute;
    transform: translateY(-50%) translateX(0%);
    filter: drop-shadow(0 0.6rem 0.25rem rgba(0, 0, 0, 0.5));
}