@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'), url('./Fonts/SegoeUI.woff') format('woff');
 }
 @font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url('./Fonts/SegoeUIBold.woff') format('woff');
  }
  

* { 
  /* Remove padding and margin */ 
    margin: 0; 
    padding: 0; 
    border: 0; 
}
*,*::before,*::after{
  box-sizing: border-box;
}
body {
  font: 62.5%/1.5 "Segoe UI Regular", "Lucida Sans", Tahoma, Verdana, sans-serif;
  
  width: 100%;
  padding: 0;

}

p {
  margin : 0;
}

ul {
  list-style: none;

}

a:link , a:visited{
  text-decoration: none;
}

#root{
    height: 100vh;
    
}