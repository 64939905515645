@import "./variables";

.news{
      &-header{
            text-align: center;
            margin-top: 2rem;
            margin-bottom: .2rem;
            text-transform: uppercase;
            &-two{
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 1.5rem;
        }
    }
    &-form{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    &-input{
        height: 1.5rem;
        background-color: transparent;
        color: $color-main;
        border-bottom: .5px solid $color-main;
        outline: none;
        &::placeholder{
            color: $color-main-light;
        }
    }
   
}