@import "./variables";
@import "./mixins";

.pantry{
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;

    position: relative;
    &-header{
        flex : .7;
        margin-left: .5rem;
       
    }
    &-body{
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;

    }
    &-info{
        flex:  1.5;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: .6rem;
        position: absolute;
        bottom: 1rem;
        left : 50%;
        transform : translateX(-50%); 
        background-color: #fff;
        border : solid .5px rgba($color-secondary, .3);
        box-shadow: 1px 6px 8px rgba($color-secondary, .7);
    } 
    &-image{
        width: 56%;
        @include respond(tablet){
            width: 65%;
        }
    }
}
.cta{
    display: flex;
    flex-direction: column;
    align-items: center;
    &-btn{
        width: 9rem;
        padding: 1rem 0.5rem;
        border-radius: 70px;
        text-transform: uppercase;
        font-size: 12px ;
        font-weight: 900;
        box-shadow: 0px 6px 14px rgba(black, .4);
        border :1px solid transparent;
        outline: none;
        
        
        &-main{
            color: $color-secondary;
            background-color:  $color-main;

        }
        &:hover {
            color : $color-secondary;
            background-color: white ;
            cursor: pointer;
            border :1px solid $color-secondary;
        } 
        &-secondary{
            color: $color-secondary;
            background-color: white;
           &:hover{
               background-color: transparent;
               
           }
        }
    }
   
    &-text{
        color: $color-secondary ;
        margin-top:2rem ;
        text-align: center;
        max-width: 95%;
        min-width: 9rem;
        font-weight: 400;
        font-size: 14.7px;
        line-height: 1.5rem;
        @include respond(tablet){
            font-size: 18.3px;
            opacity: .9;
            line-height: 1.5;
            
        }
    }
    @include respond(tablet){
        justify-content: center;
    }
}

.service{
    display: flex;
    flex-direction: column;
    width: 10rem;
    justify-content: space-between;
    color: $color-secondary;
    &-header{
        width: 100%;
        letter-spacing: .1rem;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
    }
    &-text{
        text-transform: uppercase;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        
    }
    &-icon{
        height: 2rem;
        width: 2rem;
        fill: $color-secondary;

    }
    &-label{
        padding: .4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }
}
.cta-pantry{
    position: absolute;
    width: 17rem;
    height: 15rem;
    top : 35%;
    transform: translateY(-35%);
    right : 1rem;

    @include respond(tablet){
        top : 28%;
        transform: translateY(-30%);
        width: 90%;
        right: initial;
        justify-content: center;
    }
}