@import "./variables";
@import "./mixins";

.container{
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr ;
    flex : 0 0 80%;
    @include respond(tablet){
        flex : 0 0 85%;
    }
   

}
.pantry{
    background-color: #fff;    
}
.accessories{
    background-color: $color-main;
}
